import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppCommonModule } from '@app/shared/common/app-common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { CountoModule } from 'angular2-counto';
import { ModalModule, TabsModule, TooltipModule, BsDropdownModule, PopoverModule } from 'ngx-bootstrap';


import { NgWizardModule, NgWizardConfig, THEME } from '@mond/ng-wizard';


import { AutoCompleteModule, FileUploadModule as PrimeNgFileUploadModule, PaginatorModule, ProgressBarModule, PanelModule, CheckboxModule, ButtonModule, ToolbarModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';

import { DataService } from '../main/data.service';

const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
  };



import { NgxChartsModule } from '@swimlane/ngx-charts';

import { KonvaModule } from "ng2-konva";


import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { EstimationToolRoutingModule } from './estimationtool-routing.module';
import { EstimationInputComponent } from './estimationinput/estimationinput.component';

NgxBootstrapDatePickerConfigService.registerNgxBootstrapDatePickerLocales();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        TabsModule,
        TooltipModule,
        AppCommonModule,
        UtilsModule,
        EstimationToolRoutingModule,
        CountoModule,
        NgxChartsModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        AutoCompleteModule,
        PanelModule,
        CheckboxModule,
        ButtonModule,
        ToolbarModule,
        TableModule,
        CardModule,
        ChartModule,
        InputSwitchModule,
        TabViewModule,
        KonvaModule,
        NgWizardModule.forRoot(ngWizardConfig),
    ],
    declarations: [
        EstimationInputComponent
        // RoomDesignComponent,
        // NewRoomModalComponent,
        // RoomSelectionComponent,
        // EditPlaceModalComponent,
        // RoomComponent,
        // PlacementSelectionComponent,
        // PlacementComponent,
        // PlacementWizardComponent,
        // ChoosePlacementItemContextModalComponent,
        // PlacementRuleComponent,
        // PlacementRulesComponent,
        // EditPlacementRuleModalComponent
    ],
    providers: [DataService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale }
    ]
})
export class EstimationToolModule { 
    constructor(){
        
    }

}
