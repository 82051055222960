import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Component, Injector } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { GroupBasicDto, MeasurementBasicDto, QuestionBasicDto, GroupMeasurementQuestionStatisticDto } from '@shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
//import { DataService } from '../data.service';

@Component({
    templateUrl: './estimationinput.component.html',
    animations: [appModuleAnimation()]
})
export class EstimationInputComponent extends AppComponentBase {
    
   
    //If navigate to Input shall be enabled or not 
    enableNavigation = false;


    collapse = false;
  //  selectionHeaderText = this.l('SelectSociogram');
   

    
    constructor(
        //public dataService: DataService,
        private _permissionChecker: PermissionCheckerService,
        private router: Router,
        injector: Injector
    ) {
        super(injector);
    }

    ngOnInit(): void {
        let me = this;


         
    }

        
  
       

   

}
